(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define("@draftkings/dk-my-bets-list", [], factory);
	else if(typeof exports === 'object')
		exports["@draftkings/dk-my-bets-list"] = factory();
	else
		root["@draftkings/dk-my-bets-list"] = factory();
})(self, function() {
return 